import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button, Dropdown, DropdownButton, Alert } from "react-bootstrap";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import './AgendaPage.css';
import { useTranslation } from 'react-i18next';

export function AgendaPage() {

  const [errorMessage, setErrorMessage] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [slots, setSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const user = userMetadata;
  const isSeller = selectedAppointment && selectedAppointment.seller === user.id || (userMetadata && (userMetadata.type === "seller" || userMetadata.type === "company"));
  const token = apiAccessToken;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/appointments/user/${user.id}/all/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        setAppointments(data);
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      }
    };

    fetchAppointments();
  }, [user, token]);

  useEffect(() => {
    if (!user?.id || !token) return;
    if (selectedAppointment) {
      const fetchSlots = async (appointmentId) => {
        const URL = `https://usell.be/api/slots/?appointment_id=${appointmentId}`;
        const options = {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };

        try {
          const response = await fetch(URL, options);
          if (!response.ok) throw new Error("Error fetching slots");
          const data = await response.json();

          if (isSeller) {
            setSlots(data);
          } else {
            const buyerSlots = data.filter(slot => slot.selected_slot_seller === true);
            setSlots(buyerSlots);
          }
        } catch (error) {
          console.error("Error fetching slots:", error);
        }
      };

      fetchSlots(selectedAppointment.id);
    }
  }, [selectedAppointment, isSeller, user, token]);

  const handleSlotSelect = (slotId) => {
    if (isSeller) {
      setSelectedSlots((prevSelectedSlots) => {
        if (prevSelectedSlots.includes(slotId)) {
          return prevSelectedSlots.filter(id => id !== slotId);
        }
        if (prevSelectedSlots.length < 5) {
          return [...prevSelectedSlots, slotId];
        }
        return prevSelectedSlots;
      });
    } else {
      setSelectedSlot(slotId === selectedSlot ? null : slotId);
    }
  };

  const submitSlots = async () => {
    if (!selectedAppointment || selectedSlots.length !== 5) {
      alert("You must select exactly 5 slots to submit.");
      return;
    }

    const selectedSlotDates = selectedSlots.map(slotId => {
      const slot = slots.find(s => s.id === slotId);
      return new Date(slot.date_time).toISOString();
    });

    const URL = `https://usell.be/api/appointments/${selectedAppointment.id}/submit_slots/`;
    const token = apiAccessToken;
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        slots: selectedSlotDates
      }),
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) throw new Error("Het bevestigen van een tijdstip is mislukt.");
      //const data = await response.json();
      navigate('/dashboard/afspraken');
    } catch (error) {
      console.error("Het bevestigen van een tijdstip is mislukt.:", error);
    }
  };

  const confirmSlot = async () => {
    if (!selectedSlot) return;

    const URL = `https://usell.be/api/slots/confirm/${selectedSlot}/`;
    const token = apiAccessToken;
    const options = {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Het bevestigen van een tijdstip is mislukt. Gelieve uw betaling te vervolledigen en vervolgens opnieuw te proberen");
        return;
      }

      const data = await response.json();
      setSelectedAppointment({
        ...selectedAppointment,
        appointment_date: data.appointment_date,
        status: "Accepted",
      });

      navigate('/dashboard/afspraken');
    } catch (error) {
      console.error("Failed to confirm slot:", error);
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const declineSlots = async () => {
    if (!selectedAppointment) return;

    const URL = `https://usell.be/api/appointments/${selectedAppointment.id}/reset/`;
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiAccessToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) throw new Error("Failed to reset appointment");
      navigate(`/dashboard/afspraken`);
    } catch (error) {
      console.error("Error resetting appointment:", error);
    }
  };

  const renderHourlySlots = () => {
    if (!isSeller && slots.length === 0) {
      return <p>{t('agenda11')}</p>;
    }

    return (
      <div className="hourly-slots">
        {slots.map(slot => (
          <Button
            key={slot.id}
            className={`slot ${isSeller && selectedSlots.includes(slot.id) ? 'selected' : ''} ${!isSeller && selectedSlot === slot.id ? 'selected' : ''}`}
            onClick={() => handleSlotSelect(slot.id)}
          >
            {new Date(slot.date_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            <br />
            {new Date(slot.date_time).toLocaleDateString()}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <>
      <UserNavigation />
      <div className={"agenda-hero"}>
        <div className={"hero-background agenda-background"}></div>
        <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
          <h1 className={"agenda-title"}>{t('agenda')}</h1>
        </Container>
      </div>
      <Container className={"content agenda-container"}>
        <Row>
          <DropdownButton title={t('agenda2')}>
            {appointments
              .filter(app => app.status !== "ACCEPTED" && app.status !== "COMPLETED" && app.status !== "Suspended" )
              .map(app => (
                <Dropdown.Item
                  key={app.id}
                  onClick={() => setSelectedAppointment(app)}
                >
                  {t('agenda3')} #{app.id}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </Row>

        {errorMessage && (
          <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
          </Alert>
        )}

        {selectedAppointment && (
          <Row>
            {isSeller ? (
              <>
                <h2>{t('agenda4')}</h2>
                {renderHourlySlots()}
                <Button onClick={submitSlots} disabled={selectedSlots.length !== 5}>
                  {t('agenda5')}
                </Button>
              </>
            ) : (
              <>
                <h2>{t('agenda6')}</h2>
                {renderHourlySlots()}
                {slots.length > 0 && (
                  <Row className="mt-3">
                    <Col>
                      <Button onClick={confirmSlot} disabled={!selectedSlot}>{t('agenda7')}</Button>
                      <Button onClick={declineSlots}>{t('agenda8')}</Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Row>
        )}
        {selectedAppointment && selectedAppointment.status === "Accepted" && (
          <Row>
            <h3>{t('agenda9')}</h3>
            <p>{t('agenda10')} {new Date(selectedAppointment.appointment_date).toLocaleString()}.</p>
          </Row>
        )}
      </Container>
    </>
  );
}