import React, { useEffect, useState, useContext } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';

export function RelistAuctionPage() {
    const [auctions, setAuctions] = useState([]);
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const token = apiAccessToken;
    const user = userMetadata;
    const { t } = useTranslation();

    useEffect(() => {
        const fetchCancelledAuctions = async () => {
            if (!user?.id || !token) return;
            const URL = `https://usell.be/api/user/${user.id}/disabled-auctions/`;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("Failed to fetch cancelled auctions");
                const data = await response.json();
                setAuctions(data);
            } catch (error) {
                console.error("Error fetching cancelled auctions:", error);
            }
        };

        fetchCancelledAuctions();
    }, [token, user]);

    const relistAuction = async (auctionId) => {
        const url = `https://usell.be/api/relist-auction/${auctionId}/`;
        const options = {
            method: "PUT",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) throw new Error("Failed to relist auction");
            setAuctions((prev) => prev.filter((auction) => auction.id !== auctionId));
        } catch (error) {
            console.error("Error relisting auction:", error);
        }
    };

    return (
        <>
            <UserNavigation />
            <div className={"create-auction-hero"}>
                <div className={"hero-background create-auction-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <h1 className={"appointments-title responsive-title"}>{t('relist')}</h1>
                </Container>
            </div>
            <Container className="mt-5">
                <h2 className="mb-4">{t('relist2')}</h2>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>{t('relist3')}</th>
                            <th>{t('relist4')}</th>
                            <th>{t('relist5')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auctions.map((auction) => (
                            <tr key={auction.id}>
                                <td>{auction.name}</td>
                                <td>{auction.status}</td>
                                <td>
                                    <Button variant="primary" onClick={() => relistAuction(auction.id)}>{t('relist6')}</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </>
    );
}