import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { CarList } from "../../components/CarList";
import { useContext, useEffect, useState } from "react";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';

export function WinningAuctionsPage(props) {
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const token = apiAccessToken;
    const user = userMetadata;
    const [auctions, setAuctions] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!user?.id || !token) return;

        const URL = `https://usell.be/api/winning-auctions/${user.id}/`;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        fetchData();

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                const winningAuctions = data
                    .filter(item => item.winning_bid && item.auction.status === "ONGOING");
                setAuctions(winningAuctions.map(item => item.auction));
            } catch (error) {
                console.error(error);
            }
        }
    }, [user.id, token]);

    if (!auctions) return "Loading";

    return (
        <>
            <UserNavigation />
            <div className={"winning-hero"}>
                <div className={"hero-background winning-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <h1 className="winning-titel responsive-title">{t('winning_title')}</h1>
                    <h4>{t('winning_text')}</h4>
                </Container>
            </div>

            <Container className={"content winnende"}>
                <Row>
                    <CarList auctions={auctions} following={true} />
                </Row>
            </Container>
        </>
    );
}