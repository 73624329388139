import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "join_button": "JOIN NOW",
                "usell_title": "U-SELL",
                "hero_subtitle": "UNIQUE AUCTION SITE FOR ALL VEHICLE TYPES.",
                "become_member": "BECOME A MEMBER RIGHT NOW!",
                "about_pre": "WHAT IS",
                "about_text": "We are the first transparent auction site for selling second-hand vehicles in Belgium. Usell guarantees the best price for your car by putting the owner completely in control. Every choice related to your property is entirely in your hands. Your car = your auction = your choice! Additionally, businesses can offer their VAT cars. You will be able to receive bids from our professional partners for five days, all of whom are thoroughly screened before accessing the platform and continuously monitored by our expertise with feedback from customers. This allows us to guarantee transparency, quality, safety, and the best price for our customers. Moreover, we handle all the paperwork and ensure the car is picked up anywhere in Belgium within five days. Upon collection, both you and the Usell professional confirm the transfer on our platform, and within seconds, you receive the amount for which the deal was finalized. It's your deal!",
                "mission_title": "OUR MISSION",
                "mission_text": "We aim to make the second-hand car market a reliable marketplace, keeping your convenience in mind. We strive to transform current standards into new values that we, as a company, are proud of.",
                "vision_title": "OUR VISION",
                "vision_text": "Our platform is an innovative and fully automated program, emphasizing your user experience. Transparency and customer-friendliness are the cornerstones of our platform.",
                "register_buyer_title": "BUY A VEHICLE",
                "register_buyer_text": "Want to join our professional network? Start your application here.",
                "register_seller_title": "SELL A VEHICLE",
                "register_seller_text": "Want to sell a car? Register now and start selling.",
                "sold_auctions_title": "WHAT HAVE WE HELPED SELL?",
                "loading_auctions": "Loading auctions...",
                "no_paid_auctions": "There are currently no paid auctions available.",
                "create_account_title": "CREATE AN ACCOUNT",
                "create_account_text1": "Start by creating an account on our platform.",
                "create_account_text2": "Once done, you will be guided through the system so you can easily find and enter all necessary information about your car, then add a few pictures, and you're all set.",
                "create_account_text3": "The auction can begin!",
                "auctions_title": "AUCTIONS",
                "auctions_text1": "Sell your car in 5 days! Your car will be on our auction for professionals. Accept a bid immediately or wait 24 hours for the highest offer. Upon acceptance, you will receive an advance payment. We will arrange the collection within 5 days, anywhere in Belgium. Confirm the sale at the pickup through our platform.",
                "auctions_text2": "Important: Ensure an honest description to avoid administrative costs.",
                "auctions_text3": "It's your deal!",
                // ABOUT US
                "us_title": "What does",
                "us_title2": "Usell",
                "us_title3": "stand for?",
                "pillars_title": "Our Pillars",
                "pillars_text": "As an innovative partner in the second-hand car market, we make a difference with two key pillars: transparency and user-friendliness.",
                "first_title": "Transparency (free market)",
                "first_text": "The customer is king, and we take this very seriously. The trading space offered allows for truthful feedback and knowledge for the customer. Usell plays an open hand and is the only partner that provides the customer with an honest and realistic picture of the value of their car, completely free and without obligation.",
                "last_title": "User-friendliness",
                "last_text": "Sign up and experience optimal user-friendliness. With all the emerging technologies in today's world and rapid changes, we are not blind to older generations. That's why we designed our platform assuming everyone should be able to find their way. You will be guided through the process to ensure that your experience is smooth, enjoyable, and broadening. Usell highly values feedback, so do not hesitate to send us your comments or questions.",
                "last_text2": "Throughout the entire process, you are completely in control. From the moment your car is listed online, you are free to choose which bid to accept. You literally hold time in your hands. U...sell = you sell. It�s your deal!",
                // CONTACT
                "contact_title": "CONTACT US",
                "contact_text1": "Do you have any questions or comments?",
                "contact_text2": "Let us know, and we will respond as soon as possible.",
                "contact_title2": "SEND US A MESSAGE",
                "contact_text3": "Do you have a question? Check out our",
                "contact_text4": "frequently asked questions",
                "contact_text5": "for answers!",
                // CONTACT FORM
                "naam": "NAME",
                "naam_placeholder": "Your name",
                "email": "EMAIL",
                "email_placeholder": "Your email address",
                "onderwerp": "SUBJECT",
                "onderwerp_placeholder": "Subject",
                "bericht": "MESSAGE",
                "akkoord": "I AGREE WITH USELL'S PRIVACY POLICY. I UNDERSTAND THAT USELL WILL USE MY INFORMATION TO RESPOND TO MY QUESTIONS.",
                "contact_button": "SEND",
                "socials": "REACH US THROUGH OTHER PLATFORMS",
                // NAVBAR
                "nav1": "Home",
                "nav2": "About Us",
                "nav3": "Contact",
                "nav4": "Sign In",
                "nav5": "Dashboard",
                "nav6": "Log Out",
                "footer1": "MENU",
                "footer2": "Home",
                "footer3": "About Us",
                "footer4": "Contact",
                "footer5": "CONTACT",
                "footer6": "LEGAL",
                "footer7": "Claim",
                "footer8": "Terms",
                "footer9": "Privacy Policy",
                "footer10": "Cookie Policy",
                "footer_text": "� Copyright Usell. All rights reserved.",
                //UserNavigation SELLER
                "navbar_title1": "Auctions",
                "navbar_1": "My Auctions",
                "navbar_2": "Create Auction",
                "navbar_3": "Repost Auction",
                "navbar_title2": "Calendar",
                "navbar_4": "Appointments",
                "navbar_5": "Schedule",
                "navbar_title3": "Bids",
                "navbar_title4": "My Profile",
                "navbar_title5":"Stock",
                //UserNavigation BUYER
                "navbar_6": "Winning Auctions",
                "navbar_7": "Upcoming Auctions",
                "navbar_8": "Ongoing Auctions",
                "navbar_9": "Won Auctions",
                "navbar_10":"My Stock",
                "navbar_11":"Available Stock",
                "navbar_12":"Sell Stock",
		"navbar_13":"My Stock bids",
                //DASHBOARD
                "dashboard_title": "Dashboard",
                "dashboard1": "Active Auctions",
                "dashboard2": "Manage and view your active auctions.",
                "dashboard3": "Create a New Auction",
                "dashboard4": "Easily create a new auction via the form.",
                "dashboard5": "Appointments",
                "dashboard6": "Manage and view your appointments.",
                "dashboard7": "My Profile",
                "dashboard8": "View and edit your profile.",
                "dashboard9": "Ongoing Auctions",
                "dashboard10": "View your ongoing auctions.",
                "dashboard11": "Won Auctions",
                "dashboard12": "View your won auctions.",
                "dashboard13": "Appointments",
                "dashboard14": "Manage and view your appointments.",
                "dashboard15": "My Profile",
                "dashboard16": "View and edit your profile.",
                //WINNING-AUCTIONS
                "winning_title": "WINNING AUCTIONS",
                "winning_text": "You currently have the highest bid on these auctions.",
                //FOLLOWING-AUCTIONS
                "following_title": "FOLLOWING AUCTIONS",
                "following_text": "View the auctions you are following.",
                //LOPENDE VEILINGEN
                "lopende_title": "AUCTIONS",
                "lopende_text1": "View our auctions. Found one you like?",
                "lopende_text2": "Click on it for more details.",
                //WON-AUCTIONS
                "won_title": "WON AUCTIONS",
                "won_text": "You have won these auctions as the highest bidder.",
                //APPOINTMENT
                "appointment1": "Please wait until the buyer confirms or cancels the sale after the appointment.",
                "appointment2": "Cancel",
                "appointment3": "Accept",
                "appointment4": "Open schedule",
                "appointment5": "Please wait until the buyer has accepted a time slot.",
                "appointment6": "Open schedule",
                "appointment7": "Please wait until the seller has selected 5 time slots.",
                "appointment8": "Proceed to sale",
                "appointment9": "Please wait until the other party agrees to the sale.",
                "appointment10": "Proceed to sale",
                "appointment11": "Please wait until the other party agrees to the sale.",
                "appointment12": "No date yet",
                "appointment13": "Appointments",
                "appointment14": "To be scheduled",
                "appointment15": "Scheduled appointments",
                "appointment16": "Completed appointments",
                //AGENDA
                "agenda": "Schedule",
                "agenda2": "Select an appointment",
                "agenda3": "Appointment",
                "agenda4": "Select time slots for your appointment",
                "agenda5": "Submit time slots",
                "agenda6": "Available time slots for appointment",
                "agenda7": "Accept",
                "agenda8": "Reject all",
                "agenda9": "Confirmed appointments",
                "agenda10": "Your appointment is confirmed for",
                "agenda11":"The seller has not submitted any time slots yet. Please try again later.",
                //PROFIEL
                "profile": "Remove profile picture",
                "profile1":"Profile",
                "profile2": "Personal Information",
                "profile3": "First Name",
                "profile4": "Last Name",
                "profile5": "Email",
                "profile6": "Date of Birth",
                "profile7": "Address",
                "profile8": "Street",
                "profile9": "House number",
                "profile10": "Postal code",
                "profile11": "City",
                "profile12": "Province",
                "profile13": "Country",
                "profile14": "Phone",
                "profile15": "Edit personal info",
                "profile16": "Change password",
                "profile17": "Edit personal info",
                "profile18": "Close",
                "profile19": "Save changes",
                "profile20": "Change password",
                "profile21": "Close",
                "profile22": "Save changes",
                "profile23": "Change profile picture",
                "profile24": "Cancel",
                "profile25": "Save",
                //MIJN VEILINGEN
                "userveiling": "YOUR AUCTIONS",
                "userveiling2": "Here is a list of your auctions.",
                "userveiling3": "Active auctions",
                "userveiling4": "Refresh active auctions",
                "userveiling5": "History",
                "userveiling6": "Refresh history",
                "userveiling7": "Search auctions...",
                "userveiling8": "Your active auctions",
                "userveiling9": "Your auction history",
                //BIEDINGEN
                "bid": "Bids",
                "bid2": "My auctions - Highest Bids",
                "bid3": "Auction name",
                "bid4": "Highest bid",
                "bid5": "Actions",
                "bid6": "No bids yet",
                "bid7": "Accept",
                "bid8": "Reject",
                //RELIST AUCTION
                "relist": "Blocked auctions",
                "relist2": "Blocked auctions - Repost",
                "relist3": "Auction name",
                "relist4": "Status",
                "relist5": "Actions",
                "relist6": "Repost auction",
                //CREATE AUCTION PAGE
                "create": "Create a new auction",
                "create2": "Auction details",
                "create3": "Name *",
                "create3_placeholder": "Auction name",
                "create4": "Starting price",
                "create4_placeholder": "Starting price",
                "create5": "Brand *",
                "create5_placeholder": "Select a brand",
                "create6": "Model *",
                "create6_placeholder": "Select a model",
                "create7": "Year *",
                "create7_placeholder": "Year",
                "create8": "Chassis number *",
                "create8_placeholder": "Chassis number",
                "create9": "Generation *",
                "create9_placeholder": "Select a generation",
                "create10": "Series *",
                "create10_placeholder": "Select a series",
                "create11": "Trim *",
                "create11_placeholder": "Select a trim",
                "create12": "Color *",
                "create12_placeholder": "Color",
                "create13": "Number of doors *",
                "create14": "Mileage *",
                "create15": "Engine *",
                "create16": "Transmission",
                "create16_placeholder1": "Automatic",
                "create16_placeholder2": "Semi-automatic",
                "create16_placeholder3": "Manual",
                "create17": "Drive",
                "create17_placeholder1": "Front-wheel drive",
                "create17_placeholder2": "Rear-wheel drive",
                "create17_placeholder3": "All-wheel drive",
                "create18": "Fuel",
                "create18_placeholder1": "Diesel",
                "create18_placeholder2": "Petrol",
                "create18_placeholder3": "Hybrid",
                "create18_placeholder4": "LPG",
                "create18_placeholder5": "Electric",
                "create19": "Options",
                "create19_placeholder": "Options (separate by commas)",
                "create20": "Damage",
                "create20_placeholder": "Damage (separate by commas)",
                "create21": "Board document * (Max 1)",
                "create22": "Description",
                "create22_placeholder": "Description",
                "create23": "Images (Maximum 10)",
                "create24": "Financing available",
                "create25": "VAT system",
                "create25_placeholder": "VAT system",
                "create26": "Appointment Address",
                "create27": "Street",
                "create28": "No.",
                "create29": "Postal Code",
                "create30": "City",
                "create31": "Province",
                "create32": "Country",
                "create33": "Phone number",
                "create34": "CREATE AUCTION",
                //CARCARD
                "car":"SOLD IN 1 DAY",
                "car2":"Ended",
                //STOCK
                "stock_title":"My Stockpage",
                "stock_text":"This is you're current stockgallery",
                "create43":"CREATE STOCK",
                "create44":"Create a new stock",
                "create45":"Stockdetails",
            }
        },
        nl: {
            translation: {
                "join_button": "WORD NU LID",
                "usell_title": "U-SELL",
                "hero_subtitle": "UNIEKE VEILINGSITE VOOR ELK TYPE VOERTUIG.",
                "become_member": "WORD NU LID",
                "about_pre": "WAT IS",
                "about_text": "We zijn de eerste transparante veilingsite voor de verkoop van tweedehandsvoertuigen in Belgi�. Usell garandeert de beste prijs voor jouw wagen, dit door de eigenaar volledig centraal te zetten. Zo zal elke keuze die gemaakt moet worden met betrekking tot jouw eigendom, steeds volledig in jouw handen liggen. jouw wagen = jouw veiling = jouw keuze! Eveneens als bedrijf kan je jouw btw- wagens aanbieden.Je zult gedurende 5 dagen biedingen kunnen ontvangen van onze professionele partners, die uitvoerig gescreend worden alvorens toegang te verkrijgen tot het platform en nadien continu opgevolgd worden door onze expertise en dit met feedback van de klant.Hierdoor kunnen wij transparantie, kwaliteit, veiligheid en de beste prijs garanderen aan onze klanten.Daarnaast nemen wij alle administratie voor onze rekening en zorgen we ervoor dat de wagen om het even waar in belgie wordt opgehaald en dit binnen de 5 dagen. Bij ophaling van de wagen bevestigen zowel jijzelf als de usell professional de overdracht op ons platform en binnen enkele seconden beschik je over het bedrag waarvoor de deal werd afgesloten. It's your deal!",
                "mission_title": "ONZE MISSIE",
                "mission_text": "Wij willen van de tweedehandse automarkt een betrouwbare handelsplaats maken, met jouw gebruiksgemak in het achterhoofd. We willen de huidige normen ombouwen naar nieuwe waarden waar wij als bedrijf trots op zijn.",
                "vision_title": "ONZE VISIE",
                "vision_text": "Ons platform is een innovatief en volledig geautomatiseerd programma, dat de nadruk legt op jouw gebruikservaring. Transparantie en klantvriendelijkheid zijn de pijlers van ons platform.",
                "register_buyer_title": "KOOP EEN VOERTUIG",
                "register_buyer_text": "Wil je deel uitmaken van ons professioneel netwerk? Start hier je aanvraag.",
                "register_seller_title": "VERKOOP EEN VOERTUIG",
                "register_seller_text": "Wil je een wagen verkopen? Registreer je en ga aan de slag.",
                "sold_auctions_title": "WAT HEBBEN WE AL HELPEN VERKOPEN?",
                "loading_auctions": "Veilingen laden...",
                "no_paid_auctions": "Er zijn momenteel geen betaalde veilingen beschikbaar.",
                "create_account_title": "MAAK EEN ACCOUNT",
                "create_account_text1": "Begin met een account te maken op ons platform.",
                "create_account_text2": "Hierna wordt je begeleid door de werking van het systeem zodat je zonder problemen alle nodige gegevens van jouw wagen kan terugvinden en ingeven, daarna nog een paar foto's toevoegen en klaar is kees,",
                "create_account_text3": "de veiling kan beginnen!",
                "auctions_title": "VEILINGEN",
                "auctions_text1": "Verkoop je auto in 5 dagen! Je auto staat op onze veiling voor professionals. Accepteer direct een bod of wacht 24 uur voor het hoogste bod. Bij acceptatie ontvang je direct een voorschot. We regelen de ophaling binnen 5 dagen, overal in Belgi�. Bevestig de verkoop bij de afhaling via ons platform.",
                "auctions_text2": "Belangrijk: Zorg voor een eerlijke beschrijving om administratiekosten te voorkomen.",
                "auctions_text3": "It's your deal!",
                // OVER ONS
                "us_title": "Waar staat",
                "us_title2": "Usell",
                "us_title3": "voor?",
                "pillars_title": "Onze pijlers",
                "pillars_text": "Als vernieuwende partner op de tweedehandse automarkt, maken we met twee pijlers het verschil: transparantie en gebruiksvriendelijkheid.",
                "first_title": "Transparantie (vrije markt)",
                "first_text": "Klant is koning en dit nemen wij zeer letterlijk. De aangeboden handelsruimte opent de deur naar waarheidsgetrouwe feedback en kennis voor de klant. Usell speelt open kaart en is de enige partner die de klant een eerlijk en realistisch beeld geeft van de waarde van zijn wagen en dit volledig gratis en vrijblijvend.",
                "last_title": "Gebruiksvriendelijkheid",
                "last_text": "Meld je aan en ervaar optimaal gebruiksgemak. Met alle opkomende technologie in de hedendaagse wereld en snelle aanpassingen, zijn we niet blind voor de oudere generaties. Daarom hebben we ons platform opgesteld vanuit de veronderstelling dat iedereen hier zijn weg in moet kunnen vinden. Je wordt doorheen het proces begeleid met als doelstelling om de ervaring vlot, aangenaam en verruimend te laten verlopen. Usell stelt feedback zeer op prijs, twijfel dus niet om opmerkingen of vragen aan ons te richten.",
                "last_text2": "Doorheen het hele proces heb jij de touwtjes volledig in handen. Vanaf het moment dat jouw wagen online staat, sta je vrij om te kiezen met welke bieding je akkoord gaat. Je hebt de tijd letterlijk in de hand. U�..sell = u verkoopt. It�s your deal!",
                // CONTACT
                "contact_title": "CONTACTEER ONS",
                "contact_text1": "Heb je nog vragen of opmerkingen?",
                "contact_text2": "Laat het ons weten en we antwoorden zo snel mogelijk.",
                "contact_title2": "STUUR ONS EEN BERICHTJE",
                "contact_text3": "Heb je een vraag? Bekijk eerst onze",
                "contact_text4": "veelgestelde vragen",
                "contact_text5": "voor antwoorden!",
                // CONTACT FORM
                "naam": "NAAM",
                "naam_placeholder": "Uw naam",
                "email": "E-MAILADRES",
                "email_placeholder": "E-mailadres",
                "onderwerp": "ONDERWERP",
                "onderwerp_placeholder": "Onderwerp",
                "bericht": "BERICHT",
                "akkoord": "IK GA AKKOORD MET DE PRIVACY-POLICY VAN USELL. IK BEGRIJP DAT USELL MIJN GEGEVENS GEBRUIKT OM MIJN VRAGEN TE BEANTWOORDEN.",
                "contact_button": "VERSTUUR",
                "socials": "BEREIK ONS VIA ANDERE PLATFORMEN",
                //NAVBAR
                "nav1": "Home",
                "nav2": "Over ons",
                "nav3": "Contact",
                "nav4": "Aanmelden",
                "nav5": "Dashboard",
                "nav6": "Afmelden",
                //FOOTER
                "footer1": "MENU",
                "footer2": "Home",
                "footer3": "Over ons",
                "footer4": "Contact",
                "footer5": "CONTACT",
                "footer6": "WETTELIJK",
                "footer7": "Rechtsvordering",
                "footer8": "Voorwaarden",
                "footer9": "Privacy beleid",
                "footer10": "Cookie beleid",
                "footer_text": "� Copyright Usell. Alle rechten voorbehouden. Ontwikkeld door Developmentspot door Spot Group.",
                //UserNavigation SELLER
                "navbar_title1": "Veilingen",
                "navbar_1": "Mijn veilingen",
                "navbar_2": "Maak veiling",
                "navbar_3": "Plaats veiling opnieuw",
                "navbar_title2": "Kalender",
                "navbar_4": "Afspraken",
                "navbar_5": "Agenda",
                "navbar_title3": "Biedingen",
                "navbar_title4": "Mijn profiel",
                "navbar_title5":"Stock",
                //UserNavigation BUYER
                "navbar_6": "Winnende veilingen",
                "navbar_7": "Volgende veilingen",
                "navbar_8": "Lopende veilingen",
                "navbar_9": "Gewonnen veilingen",
                "navbar_10":"Mijn Stock",
                "navbar_11":"Beschikbare Stock",
                "navbar_12":"Verkoop Stock",
		"navbar_13":"Mijn stock biedingen",
                //DASHBOARD
                "dashboard_title": "Dashboard",
                "dashboard1": "Actieve veilingen",
                "dashboard2": "Beheer en bekijk je actieve veilingen.",
                "dashboard3": "Maak een nieuwe veiling",
                "dashboard4": "Maak eenvoudig een nieuwe veiling aan via het formulier",
                "dashboard5": "Afspraken",
                "dashboard6": "Beheer en bekijk je afspraken.",
                "dashboard7": "Mijn Profiel",
                "dashboard8": "Bekijk en bewerk je profiel.",
                "dashboard9": "Lopende veilingen",
                "dashboard10": "Bekijk je lopende veilingen.",
                "dashboard11": "Gewonnen veilingen",
                "dashboard12": "Bekijk je gewonnen veilingen.",
                "dashboard13": "Afspraken",
                "dashboard14": "Beheer en bekijk je afspraken.",
                "dashboard15": "Mijn Profiel",
                "dashboard16": "Bekijk en bewerk je profiel.",
                //WINNING-AUCTIONS
                "winning_title":"WINNENDE VEILINGEN",
                "winning_text":"Bij deze veilingen heeft u momenteel het hoogste bod.",
                //FOLLOWING-AUCTIONS
                "following_title":"VOLGENDE VEILINGEN",
                "following_text":"Bekijk de veilingen die je volgt.",
                //LOPENDE VEILINGEN
                "lopende_title":"VEILINGEN",
                "lopende_text1":"Bekijk onze veilingen, heb je eentje gevonden die je leuk vindt?",
                "lopende_text2":"Klik erop en bekijk meer details.",
                //WON-AUCTIONS
                "won_title":"GEWONNEN VEILINGEN",
                "won_text":"Bij deze veilingen heeft u gewonnen als hoogste bieder",
                //APPOINTMENT
                "appointment1":"Gelieve te wachten tot de koper de verkoop bevestigt of annuleert na de afspraak.",
                "appointment2":"Annuleren",
                "appointment3":"Accepteren",
                "appointment4":"Open agenda",
                "appointment5":"Gelieve te wachten tot de koper een tijdslot heeft geaccepteerd.",
                "appointment6":"Open agenda",
                "appointment7":"Gelieve te wachten tot de verkoper 5 tijdslots heeft geselecteerd.",
                "appointment8":"Doorgaan tot verkoop",
                "appointment9":"Gelieve te wachten tot de andere partij akkoord gaat met verkoop.",
                "appointment10":"Doorgaan tot verkoop",
                "appointment11":"Gelieve te wachten tot de andere partij akkoord gaat met verkoop.",
                "appointment12":"Nog geen datum",
                "appointment13":"Afspraken",
                "appointment14":"Nog te plannen",
                "appointment15":"Geplande afspraken",
                "appointment16":"Voltooide afspraken",
                //AGENDA
                "agenda":"Agenda",
                "agenda2":"Selecteer een afspraak",
                "agenda3":"Afspraak",
                "agenda4":"Selecteer tijdsloten voor je afspraak",
                "agenda5":"dien tijdsloten in",
                "agenda6":"Beschikbare tijdslots voor afspraak",
                "agenda7":"Accepteer",
                "agenda8":"Weiger alle",
                "agenda9":"Bevestigde afspraken",
                "agenda10":"Uw afspraak is bevestigd voor",
                "agenda11":"De verkoper heeft nog geen tijdslots ingediend. Gelieve later opnieuw te proberen.",
                //PROFIEL
                "profile":"Verwijder profielfoto",
                "profile1":"Profiel",
                "profile2":"Persoonlijke gegevens",
                "profile3":"Voornaam",
                "profile4":"Achternaam",
                "profile5":"Email",
                "profile6":"Geboortedatum",
                "profile7":"Adres",
                "profile8":"Straat",
                "profile9":"Huisnummer",
                "profile10":"Postcode",
                "profile11":"Stad",
                "profile12":"Provincie",
                "profile13":"Land",
                "profile14":"Tel",
                "profile15":"Wijzig persoonlijke info",
                "profile16":"Wijzig wachtwoord",
                "profile17":"Wijzig persoonlijke info",
                "profile18":"Sluiten",
                "profile19":"Wijzigingen opslaan",
                "profile20":"Wijzig wachtwoord",
                "profile21":"Sluiten",
                "profile22":"Wijzigingen opslaan",
                "profile23":"Pas profielfoto aan",
                "profile24":"Annuleren",
                "profile25":"Opslaan",
                //MIJN VEILINGEN
                "userveiling":"UW VEILINGEN",
                "userveiling2":"Hier vindt u een lijst van uw veilingen.",
                "userveiling3":"Actieve veilingen",
                "userveiling4":"Ververs actieve veilingen",
                "userveiling5":"Geschiedenis",
                "userveiling6":"Ververs geschiedenis",
                "userveiling7":"Zoek veilingen...",
                "userveiling8":"Uw actieve veilingen",
                "userveiling9":"Uw veilingsgeschiedenis",
                //BIEDINGEN
                "bid":"Biedingen",
                "bid2":"Mijn veilingen - Hoogste Bids",
                "bid3":"Veiling naam",
                "bid4":"Hoogste bod",
                "bid5":"Handelingen",
                "bid6":"Nog geen biedingen",
                "bid7":"Accepteer",
                "bid8":"Weiger",
                //RELIST AUCTION
                "relist":"Geblokkeerde veilingen",
                "relist2":"Geblokkeerde veilingen - Herplaats",
                "relist3":"Veiling naam",
                "relist4":"Status",
                "relist5":"Handelingen",
                "relist6":"Veiling opnieuw plaatsen",

                //CREATE AUCTION PAGE
                "create":"Maak een nieuwe veiling",
                "create2":"Veilingdetails",
                "create3":"Naam *",
                "create3_placeholder":"Naam van de veiling",
                "create4":"Startprijs",
                "create4_placeholder":"Startprijs",
                "create5":"Merk *",
                "create5_placeholder":"Selecteer een merk",
                "create6":"Model *",
                "create6_placeholder":"Selecteer een model",
                "create7":"Bouwjaar *",
                "create7_placeholder":"Bouwjaar",
                "create8":"Chassisnummer *",
                "create8_placeholder":"Chassisnummer",
                "create9":"Generatie *",
                "create9_placeholder":"Selecteer een generatie",
                "create10":"Serie *",
                "create10_placeholder":"Selecteer een serie",
                "create11":"Uitvoering *",
                "create11_placeholder":"Selecteer een uitvoering",
                "create12":"Kleur *",
                "create12_placeholder":"Kleur",
                "create13":"Aantal deuren *",
                "create14":"Kilometerstand *",
                "create15":"Motor *",
                "create16":"Transmissie",
                "create16_placeholder1":"Automaat",
                "create16_placeholder2":"Halfautomaat",
                "create16_placeholder3":"Manueel",
                "create17":"Aandrijving",
                "create17_placeholder1":"Voorwielaandrijving",
                "create17_placeholder2":"Achterwielaandrijving",
                "create17_placeholder3":"Vierwielaandrijving",
                "create18":"Brandstof",
                "create18_placeholder1":"Diesel",
                "create18_placeholder2":"Benzine",
                "create18_placeholder3":"Hybride",
                "create18_placeholder4":"LPG",
                "create18_placeholder5":"Elektrisch",
                "create19":"Opties",
                "create19_placeholder":"Opties (scheid ze met een komma)",
                "create20":"Schade",
                "create20_placeholder":"Schade (scheid ze met een komma)",
                "create21":"Gelijkvormigheidsattest * (Max 1)",
                "create22":"Beschrijving",
                "create22_placeholder":"Beschrijving",
                "create23":"Afbeeldingen (Maximaal 10)",
                "create24":"Financiering lopend",
                "create25":"BTW - Stelsel",
                "create25_placeholder":"BTW - Stelsel",
                "create26":"Afspraakadres",
                "create27":"Straat",
                "create28":"Nr.",
                "create29":"Postcode",
                "create30":"Stad",
                "create31":"Provincie",
                "create32":"Land",
                "create33":"Telefoonnummer",
                "create34":"MAAK VEILING",
                "create35":"Inschrijvingsbewijs * (Max 1)",
                "create36":"Keuringsattest * (Max 1)",
                "create37":"Wagen onder financiering",
                //CARCARD
                "car":"VERKOCHT IN 1 DAG",
                "car2":"Afgelopen",
                //STOCK
                "stock_title":"Mijn Stockpagina",
                "stock_text":"Dit is jouw stock",
                "create43":"MAAK STOCK",
                "create44":"Maak een nieuwe stock aan",
                "create45":"Stockdetails",
            }
        },
        fr: {
            translation: {
                "join_button": "DEVENEZ MEMBRE",
                "usell_title": "U-SELL",
                "hero_subtitle": "SITE D'ENCH�RES UNIQUE POUR TOUS TYPES DE V�HICULES.",
                "become_member": "DEVENEZ MEMBRE MAINTENANT!",
                "about_pre": "QU'EST-CE QUE",
                "about_text": "Nous sommes le premier site d'ench�res transparent pour la vente de v�hicules d'occasion en Belgique. Usell garantit le meilleur prix pour votre voiture en mettant le propri�taire enti�rement aux commandes. Toutes les d�cisions concernant votre v�hicule sont entre vos mains. Votre voiture = votre ench�re = votre choix! M�me en tant qu'entreprise, vous pouvez proposer vos voitures TVA. Vous pourrez recevoir des offres de nos partenaires professionnels pendant 5 jours, qui sont minutieusement contr�l�s avant d'acc�der � la plateforme et continuellement suivis gr�ce aux retours des clients. Cela garantit la transparence, la qualit�, la s�curit� et le meilleur prix � nos clients. De plus, nous nous occupons de toute l'administration et nous nous assurons que la voiture est r�cup�r�e n'importe o� en Belgique dans les 5 jours. Lors de la r�cup�ration, vous et le professionnel usell confirmez le transfert sur notre plateforme, et en quelques secondes, vous disposez du montant pour lequel l'accord a �t� conclu. C'est votre accord!",
                "mission_title": "NOTRE MISSION",
                "mission_text": "Nous voulons faire du march� des voitures d'occasion un lieu de commerce fiable, toujours en tenant compte de votre facilit� d'utilisation. Nous visons � transformer les normes actuelles en de nouvelles valeurs dont nous sommes fiers en tant qu'entreprise.",
                "vision_title": "NOTRE VISION",
                "vision_text": "Notre plateforme est un programme innovant et enti�rement automatis� qui met l'accent sur votre exp�rience utilisateur. La transparence et la convivialit� sont les pierres angulaires de notre plateforme.",
                "register_buyer_title": "ACHETER UN V�HICULE",
                "register_buyer_text": "Souhaitez-vous rejoindre notre r�seau professionnel? Commencez votre demande ici.",
                "register_seller_title": "VENDRE UN V�HICULE",
                "register_seller_text": "Voulez-vous vendre une voiture? Inscrivez-vous et commencez.",
                "sold_auctions_title": "QU'AVONS-NOUS AID� � VENDRE?",
                "loading_auctions": "Chargement des ench�res...",
                "no_paid_auctions": "Il n'y a actuellement aucune ench�re pay�e disponible.",
                "create_account_title": "CR�ER UN COMPTE",
                "create_account_text1": "Commencez par cr�er un compte sur notre plateforme.",
                "create_account_text2": "Ensuite, vous serez guid� � travers le syst�me pour que vous puissiez facilement trouver et entrer toutes les informations n�cessaires sur votre voiture, puis ajouter quelques photos et vous avez termin�.",
                "create_account_text3": "L'ench�re peut commencer!",
                "auctions_title": "ENCH�RES",
                "auctions_text1": "Vendez votre voiture en 5 jours! Votre voiture sera mise en ench�re pour les professionnels. Vous pouvez accepter une offre imm�diatement ou attendre 24 heures pour obtenir l'offre la plus �lev�e. D�s acceptation, vous recevrez un acompte. Nous organisons la r�cup�ration sous 5 jours, partout en Belgique. Confirmez la vente lors de la collecte via notre plateforme.",
                "auctions_text2": "Important : Assurez-vous de fournir une description honn�te pour �viter des frais administratifs.",
                "auctions_text3": "C'est votre accord!",

                // � PROPOS DE NOUS
                "us_title": "Que repr�sente",
                "us_title2": "Usell",
                "us_title3": "pour?",
                "pillars_title": "Nos Piliers",
                "pillars_text": "En tant que partenaire innovant sur le march� des voitures d'occasion, nous faisons la diff�rence avec deux piliers : la transparence et la convivialit�.",
                "first_title": "Transparence (march� libre)",
                "first_text": "Le client est roi, et nous prenons cela tr�s au s�rieux. L'espace commercial offert permet des retours et des connaissances honn�tes pour le client. Usell joue cartes sur table et est le seul partenaire qui fournit au client une vision honn�te et r�aliste de la valeur de sa voiture, totalement gratuit et sans engagement.",
                "last_title": "Convivialit�",
                "last_text": "Inscrivez-vous et profitez d'une convivialit� optimale. Avec toutes les nouvelles technologies �mergentes dans le monde actuel et les changements rapides, nous ne sommes pas aveugles aux g�n�rations plus �g�es. C'est pourquoi nous avons con�u notre plateforme en partant du principe que tout le monde doit pouvoir s'y retrouver. Vous serez guid� tout au long du processus pour que votre exp�rience soit fluide, agr�able et enrichissante. Usell accorde une grande importance aux retours d'exp�rience, alors n'h�sitez pas � nous envoyer vos commentaires ou questions.",
                "last_text2": "Tout au long du processus, vous gardez le contr�le total. D�s que votre voiture est mise en ligne, vous �tes libre de choisir quelle offre accepter. Vous avez litt�ralement le temps en main. U.....sell = vous vendez. C'est votre accord!",
                // CONTACT
                "contact_title": "CONTACTEZ-NOUS",
                "contact_text1": "Avez-vous des questions ou des commentaires?",
                "contact_text2": "Faites-le nous savoir, et nous vous r�pondrons d�s que possible.",
                "contact_title2": "ENVOYEZ-NOUS UN MESSAGE",
                "contact_text3": "Vous avez une question? Consultez d'abord notre",
                "contact_text4": "foire aux questions",
                "contact_text5": "pour obtenir des r�ponses!",
                // FORMULAIRE DE CONTACT
                "naam": "NOM",
                "naam_placeholder": "Votre nom",
                "email": "E-MAIL",
                "email_placeholder": "Votre adresse e-mail",
                "onderwerp": "OBJET",
                "onderwerp_placeholder": "Objet",
                "bericht": "MESSAGE",
                "akkoord": "JE SUIS D'ACCORD AVEC LA POLITIQUE DE CONFIDENTIALIT� DE USELL. JE COMPRENDS QUE USELL UTILISERA MES DONN�ES POUR R�PONDRE � MES QUESTIONS.",
                "contact_button": "ENVOYER",
                "socials": "CONTACTEZ-NOUS PAR D'AUTRES PLATEFORMES",
                // NAVBAR
                "nav1": "Accueil",
                "nav2": "� propos de nous",
                "nav3": "Contact",
                "nav4": "Connexion",
                "nav5": "Tableau de bord",
                "nav6": "D�connexion",
                "footer1": "MENU",
                "footer2": "Accueil",
                "footer3": "� propos de nous",
                "footer4": "Contact",
                "footer5": "CONTACT",
                "footer6": "L�GAL",
                "footer7": "R�clamation",
                "footer8": "Conditions",
                "footer9": "Politique de confidentialit�",
                "footer10": "Politique de cookies",
                "footer_text": "� Copyright Usell. Tous droits r�serv�s.",
                //UserNavigation SELLER
                "navbar_title1": "Ench�res",
                "navbar_1": "Mes Ench�res",
                "navbar_2": "Cr�er Ench�re",
                "navbar_3": "R�poster Ench�re",
                "navbar_title2": "Calendrier",
                "navbar_4": "Rendez-vous",
                "navbar_5": "Agenda",
                "navbar_title3": "Offres",
                "navbar_title4": "Mon Profil",
                "navbar_title5":"Stock",
                //UserNavigation BUYER
                "navbar_6": "Ench�res Gagnantes",
                "navbar_7": "Prochaines Ench�res",
                "navbar_8": "Ench�res en Cours",
                "navbar_9": "Ench�res Gagn�es",
                "navbar_10":"My Stock",
                "navbar_11":"Available Stock",
                "navbar_12":"Sell Stock",
		"navbar_13":"Offres des Stock",
                //DASHBOARD
                "dashboard_title": "Tableau de bord",
                "dashboard1": "Ench�res Actives",
                "dashboard2": "G�rez et consultez vos ench�res actives.",
                "dashboard3": "Cr�er une Nouvelle Ench�re",
                "dashboard4": "Cr�ez facilement une nouvelle ench�re via le formulaire.",
                "dashboard5": "Rendez-vous",
                "dashboard6": "G�rez et consultez vos rendez-vous.",
                "dashboard7": "Mon Profil",
                "dashboard8": "Consultez et modifiez votre profil.",
                "dashboard9": "Ench�res en Cours",
                "dashboard10": "Consultez vos ench�res en cours.",
                "dashboard11": "Ench�res Gagn�es",
                "dashboard12": "Consultez vos ench�res gagn�es.",
                "dashboard13": "Rendez-vous",
                "dashboard14": "G�rez et consultez vos rendez-vous.",
                "dashboard15": "Mon Profil",
                "dashboard16": "Consultez et modifiez votre profil.",
                //WINNING-AUCTIONS
                "winning_title": "ENCH�RES GAGNANTES",
                "winning_text": "Vous avez actuellement l'ench�re la plus �lev�e sur ces ench�res.",
                //FOLLOWING-AUCTIONS
                "following_title": "ENCH�RES SUIVANTES",
                "following_text": "Consultez les ench�res que vous suivez.",
                //LOPENDE VEILINGEN
                "lopende_title": "ENCH�RES",
                "lopende_text1": "Consultez nos ench�res. Trouv� quelque chose que vous aimez?",
                "lopende_text2": "Cliquez dessus pour plus de d�tails.",
                //WON-AUCTIONS
                "won_title": "ENCH�RES GAGN�ES",
                "won_text": "Vous avez gagn� ces ench�res en tant qu'ench�risseur le plus �lev�.",
                //APPOINTMENT
                "appointment1": "Veuillez attendre que l'acheteur confirme ou annule la vente apr�s le rendez-vous.",
                "appointment2": "Annuler",
                "appointment3": "Accepter",
                "appointment4": "Ouvrir l'agenda",
                "appointment5": "Veuillez attendre que l'acheteur ait accept� un cr�neau horaire.",
                "appointment6": "Ouvrir l'agenda",
                "appointment7": "Veuillez attendre que le vendeur ait s�lectionn� 5 cr�neaux horaires.",
                "appointment8": "Proc�der � la vente",
                "appointment9": "Veuillez attendre que l'autre partie accepte la vente.",
                "appointment10": "Proc�der � la vente",
                "appointment11": "Veuillez attendre que l'autre partie accepte la vente.",
                "appointment12": "Pas encore de date",
                "appointment13": "Rendez-vous",
                "appointment14": "� planifier",
                "appointment15": "Rendez-vous planifi�s",
                "appointment16": "Rendez-vous termin�s",
                //AGENDA
                "agenda": "Agenda",
                "agenda2": "S�lectionnez un rendez-vous",
                "agenda3": "Rendez-vous",
                "agenda4": "S�lectionnez des cr�neaux horaires pour votre rendez-vous",
                "agenda5": "Soumettre des cr�neaux horaires",
                "agenda6": "Cr�neaux horaires disponibles pour le rendez-vous",
                "agenda7": "Accepter",
                "agenda8": "Rejeter tout",
                "agenda9": "Rendez-vous confirm�s",
                "agenda10": "Votre rendez-vous est confirm� pour",
                "agenda11":"Le vendeur n'a pas encore soumis de cr�neaux horaires. Veuillez r�essayer plus tard.",
                //PROFIEL
                "profile": "Supprimer la photo de profil",
                "profile1":"Profil",
                "profile2": "Informations Personnelles",
                "profile3": "Pr�nom",
                "profile4": "Nom",
                "profile5": "Email",
                "profile6": "Date de Naissance",
                "profile7": "Adresse",
                "profile8": "Rue",
                "profile9": "Num�ro",
                "profile10": "Code Postal",
                "profile11": "Ville",
                "profile12": "Province",
                "profile13": "Pays",
                "profile14": "T�l�phone",
                "profile15": "Modifier les informations personnelles",
                "profile16": "Changer le mot de passe",
                "profile17": "Modifier les informations personnelles",
                "profile18": "Fermer",
                "profile19": "Sauvegarder les modifications",
                "profile20": "Changer le mot de passe",
                "profile21": "Fermer",
                "profile22": "Sauvegarder les modifications",
                "profile23": "Modifier la photo de profil",
                "profile24": "Annuler",
                "profile25": "Sauvegarder",
                //MIJN VEILINGEN
                "userveiling": "VOS ENCH�RES",
                "userveiling2": "Voici une liste de vos ench�res.",
                "userveiling3": "Ench�res Actives",
                "userveiling4": "Rafra�chir les ench�res actives",
                "userveiling5": "Historique",
                "userveiling6": "Rafra�chir l'historique",
                "userveiling7": "Rechercher des ench�res...",
                "userveiling8": "Vos ench�res actives",
                "userveiling9": "Votre historique des ench�res",
                //BIEDINGEN
                "bid": "Offres",
                "bid2": "Mes ench�res - Offres les plus �lev�es",
                "bid3": "Nom de l'ench�re",
                "bid4": "Offre la plus �lev�e",
                "bid5": "Actions",
                "bid6": "Pas encore d'offres",
                "bid7": "Accepter",
                "bid8": "Rejeter",
                //RELIST AUCTION
                "relist": "Ench�res Bloqu�es",
                "relist2": "Ench�res Bloqu�es - R�poster",
                "relist3": "Nom de l'ench�re",
                "relist4": "Statut",
                "relist5": "Actions",
                "relist6": "R�poster l'ench�re",
                //CREATE AUCTION PAGE
                "create": "Cr�er une nouvelle ench�re",
                "create2": "D�tails de l'ench�re",
                "create3": "Nom *",
                "create3_placeholder": "Nom de l'ench�re",
                "create4": "Prix de d�part",
                "create4_placeholder": "Prix de d�part",
                "create5": "Marque *",
                "create5_placeholder": "S�lectionnez une marque",
                "create6": "Mod�le *",
                "create6_placeholder": "S�lectionnez un mod�le",
                "create7": "Ann�e *",
                "create7_placeholder": "Ann�e",
                "create8": "Num�ro de ch�ssis *",
                "create8_placeholder": "Num�ro de ch�ssis",
                "create9": "G�n�ration *",
                "create9_placeholder": "S�lectionnez une g�n�ration",
                "create10": "S�rie *",
                "create10_placeholder": "S�lectionnez une s�rie",
                "create11": "Finition *",
                "create11_placeholder": "S�lectionnez une finition",
                "create12": "Couleur *",
                "create12_placeholder": "Couleur",
                "create13": "Nombre de portes *",
                "create14": "Kilom�trage *",
                "create15": "Moteur *",
                "create16": "Transmission",
                "create16_placeholder1": "Automatique",
                "create16_placeholder2": "Semi-automatique",
                "create16_placeholder3": "Manuelle",
                "create17": "Transmission",
                "create17_placeholder1": "Traction avant",
                "create17_placeholder2": "Traction arri�re",
                "create17_placeholder3": "Quatre roues motrices",
                "create18": "Carburant",
                "create18_placeholder1": "Diesel",
                "create18_placeholder2": "Essence",
                "create18_placeholder3": "Hybride",
                "create18_placeholder4": "GPL",
                "create18_placeholder5": "�lectrique",
                "create19": "Options",
                "create19_placeholder": "Options (s�parez-les par des virgules)",
                "create20": "Dommages",
                "create20_placeholder": "Dommages (s�parez-les par des virgules)",
                "create21": "Document de bord * (Max 1)",
                "create22": "Description",
                "create22_placeholder": "Description",
                "create23": "Images (Maximum 10)",
                "create24": "Financement disponible",
                "create25": "Num�ro de TVA du v�hicule",
                "create25_placeholder": "Num�ro de TVA du v�hicule",
                "create26": "Adresse de rendez-vous",
                "create27": "Rue",
                "create28": "Num�ro",
                "create29": "Code postal",
                "create30": "Ville",
                "create31": "Province",
                "create32": "Pays",
                "create33": "Num�ro de t�l�phone",
                "create34": "CR�ER L'ENCH�RE",
                //CARCARD
                "car":"VENDU EN 1 JOUR",
                "car2":"Termin�",
                //STOCK
                "stock_title":"Mijn Stock",
                "stock_text":"Dit is jouw stock",
                "create43":"MAAK STOCK",
                "create44":"Maak een nieuwe stock aan",
                "create45":"Stockdetails",
            }
        }
    },
    lng: "nl",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;