import React, { useEffect, useState, useContext } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function MyStockBidsPage() {
    const [forsaleStocks, setForsaleStocks] = useState([]);
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const user = userMetadata;
    const token = apiAccessToken;
    const { t } = useTranslation();

    useEffect(() => {
        const fetchForsaleStocks = async () => {
            if (!user?.id || !token) return;
            const URL = `https://usell.be/api/stock/forsale-stock/${user.id}/`;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setForsaleStocks(data);
            } catch (error) {
                console.error("Error fetching forsale stock:", error);
            }
        };

        fetchForsaleStocks();
    }, [token, user]);

    const handleBidStock = async (stockItemId, action) => {
        const url = `https://usell.be/api/stock/${stockItemId}/${action}-bid/`;
        const options = {
            method: "POST",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(url, options);

            if (response.status === 302) {
                const data = await response.json();
                if (data.onboarding_url) {
                    window.location.href = data.onboarding_url;
                    return;
                }
            }

            if (!response.ok) throw new Error(`Failed to ${action} bid`);

            setForsaleStocks((prev) => prev.filter((stockItem) => stockItem.id !== stockItemId));
        } catch (error) {
            console.error(`Error ${action} bid:`, error);
        }
    };

    console.log(forsaleStocks);

    return (
        <>
            <UserNavigation />
            <div className={"appointments-hero"}>
                <div className={"hero-background appointments-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <h1 className={"appointments-title responsive-title"}>{t('bid')}</h1>
                </Container>
            </div>
            <section className="bids-background">
                <Container>
                    <h2 className="mb-4 shadow-text">{t('bid2')}</h2>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>{t('bid3')}</th>
                                <th>{t('bid4')}</th>
                                <th>{t('bid5')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forsaleStocks && forsaleStocks.map((stockItem) => {
                                const highestBid = stockItem.stock_bids && stockItem.stock_bids.length > 0
                                    ? stockItem.stock_bids.sort((a, b) => b.amount - a.amount)[0]
                                    : null;
                                return (
                                    <tr key={stockItem.id}>
                                        <td>{stockItem.name}</td>
                                        <td><FontAwesomeIcon icon={faEuroSign} />{highestBid ? highestBid.amount : t('bid6')}</td>
                                        <td>
                                            <Button variant="success" onClick={() => handleBidStock(stockItem.id, "accept")}>{t('bid7')}</Button>
                                            <Button variant="danger" className="ms-2" onClick={() => handleBidStock(stockItem.id, "decline")}>{t('bid8')}</Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Container>
            </section>
        </>
    );
}