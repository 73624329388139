import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import React, { useContext, useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from 'react-i18next';
import registerAnimation from "../lotties/register.json";
import carAnimation from "../lotties/car.json";
import { CarCard } from "../components/CarCard";
import { AuthContext } from '../contexts/AuthContext';


export function IndexPage(props) {

    const { loginWithRedirect, userMetadata, apiAccessToken } = useContext(AuthContext);
    const [paidAuctions, setPaidAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchPaidAuctions = async () => {
            if (!userMetadata || !apiAccessToken) return;

            const URL = "https://usell.be/api/paid-auctions/";
            const token = apiAccessToken;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error(`Failed to fetch: ${response.statusText}`);
                const data = await response.json();
                setPaidAuctions(data);
            } catch (error) {
                console.error("Error fetching paid auctions:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPaidAuctions();
    }, [userMetadata, apiAccessToken]);

    return (
        <>
            <div className={"hero"}
                style={{ background: "linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url('images/index-hero.jpg') no-repeat fixed 50%" }}>
                <Container>
                    <h1><span className={"usell-u"}>U</span>SELL</h1>
                    <h2>{t('hero_subtitle')}</h2>
                    <button className={"join-button"} onClick={loginWithRedirect}>{t('become_member')}</button>
                </Container>
            </div>
            <Container className={"content indexText"}>
                <Row>
                    <Col className="index-hero-text" lg={5}>
                        <h3>{t('about_pre')} <span className={"usell-u smaller"}>U</span>SELL?</h3>
                        <p>
                            {t('about_text')}
                        </p>
                    </Col>
                    <Col lg={7}>
                        <video controls preload={"metadata"} className={"video-player vertical-center"}>
                            <source src={"videos/about-nl.mp4#t=0.8"} type="video/mp4" />
                        </video>
                    </Col>
                </Row>
            </Container>
            <Container className={"content"}>
                <Row>
                    <Col as={Link} onClick={loginWithRedirect} lg={6}>
                        <div className={"register register-buyer indexPage-content"}>
                            <svg stroke={"currentColor"} fill={"currentColor"} strokeWidth={"0"} viewBox={"0 0 512 512"}
                                color={"rgba(255,255,255,0.5)"} height={"100px"}
                                width={"100px"} xmlns={"http://www.w3.org/2000/svg"}
                                style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                                <path
                                    d={"M298.9 24.31c-14.9.3-25.6 3.2-32.7 8.4l-97.3 52.1-54.1 73.59c-11.4 17.6-3.3 51.6 32.3 29.8l39-51.4c49.5-42.69 150.5-23.1 102.6 62.6-23.5 49.6-12.5 73.8 17.8 84l13.8-46.4c23.9-53.8 68.5-63.5 66.7-106.9l107.2 7.7-1-112.09-194.3-1.4zM244.8 127.7c-17.4-.3-34.5 6.9-46.9 17.3l-39.1 51.4c10.7 8.5 21.5 3.9 32.2-6.4 12.6 6.4 22.4-3.5 30.4-23.3 3.3-13.5 8.2-23 23.4-39zm-79.6 96c-.4 0-.9 0-1.3.1-3.3.7-7.2 4.2-9.8 12.2-2.7 8-3.3 19.4-.9 31.6 2.4 12.1 7.4 22.4 13 28.8 5.4 6.3 10.4 8.1 13.7 7.4 3.4-.6 7.2-4.2 9.8-12.1 2.7-8 3.4-19.5 1-31.6-2.5-12.2-7.5-22.5-13-28.8-4.8-5.6-9.2-7.6-12.5-7.6zm82.6 106.8c-7.9.1-17.8 2.6-27.5 7.3-11.1 5.5-19.8 13.1-24.5 20.1-4.7 6.9-5.1 12.1-3.6 15.2 1.5 3 5.9 5.9 14.3 6.3 8.4.5 19.7-1.8 30.8-7.3 11.1-5.5 19.8-13 24.5-20 4.7-6.9 5.1-12.2 3.6-15.2-1.5-3.1-5.9-5.9-14.3-6.3-1.1-.1-2.1-.1-3.3-.1zm-97.6 95.6c-4.7.1-9 .8-12.8 1.9-8.5 2.5-13.4 7-15 12.3-1.7 5.4 0 11.8 5.7 18.7 5.8 6.8 15.5 13.3 27.5 16.9 11.9 3.6 23.5 3.5 32.1.9 8.6-2.5 13.5-7 15.1-12.3 1.6-5.4 0-11.8-5.8-18.7-5.7-6.8-15.4-13.3-27.4-16.9-6.8-2-13.4-2.9-19.4-2.8z"}></path>
                            </svg>
                            <div className={"vertical-center text-center register-content"}>
                                <h5>{t('register_buyer_title')}</h5>
                                <p>{t('register_buyer_text')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col as={Link} onClick={loginWithRedirect} lg={6}>
                        <div className={"register register-seller indexPage-content"}>
                            <svg stroke={"currentColor"} fill={"currentColor"} strokeWidth={"0"} viewBox={"0 0 512 512"}
                                color={"rgba(255,255,255,0.5)"} className={"absolute top-0 left-0"} height={"100px"}
                                width={"100px"} xmlns={"http://www.w3.org/2000/svg"}
                                style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                                <path
                                    d={"M258 21.89c-.5 0-1.2 0-1.8.12-4.6.85-10.1 5.1-13.7 14.81-3.8 9.7-4.6 23.53-1.3 38.34 3.4 14.63 10.4 27.24 18.2 34.94 7.6 7.7 14.5 9.8 19.1 9 4.8-.7 10.1-5.1 13.7-14.7 3.8-9.64 4.8-23.66 1.4-38.35-3.5-14.8-10.4-27.29-18.2-34.94-6.6-6.8-12.7-9.22-17.4-9.22zM373.4 151.4c-11 .3-24.9 3.2-38.4 8.9-15.6 6.8-27.6 15.9-34.2 24.5-6.6 8.3-7.2 14.6-5.1 18.3 2.2 3.7 8.3 7.2 20 7.7 11.7.7 27.5-2.2 43-8.8 15.5-6.7 27.7-15.9 34.3-24.3 6.6-8.3 7.1-14.8 5-18.5-2.1-3.8-8.3-7.1-20-7.5-1.6-.3-3-.3-4.6-.3zm-136.3 92.9c-6.6.1-12.6.9-18 2.3-11.8 3-18.6 8.4-20.8 14.9-2.5 6.5 0 14.3 7.8 22.7 8.2 8.2 21.7 16.1 38.5 20.5 16.7 4.4 32.8 4.3 44.8 1.1 12.1-3.1 18.9-8.6 21.1-15 2.3-6.5 0-14.2-8.1-22.7-7.9-8.2-21.4-16.1-38.2-20.4-9.5-2.5-18.8-3.5-27.1-3.4zm160.7 58.1L336 331.7c4.2.2 14.7.5 14.7.5l6.6 8.7 54.7-28.5-14.2-10zm-54.5.1l-57.4 27.2c5.5.3 18.5.5 23.7.8l49.8-23.6-16.1-4.4zm92.6 10.8l-70.5 37.4 14.5 18.7 74.5-44.6-18.5-11.5zm-278.8 9.1a40.33 40.33 0 0 0-9 1c-71.5 16.5-113.7 17.9-126.2 17.9H18v107.5s11.6-1.7 30.9-1.8c37.3 0 103 6.4 167 43.8 3.4 2.1 10.7 2.9 19.8 2.9 24.3 0 61.2-5.8 69.7-9C391 452.6 494 364.5 494 364.5l-32.5-28.4s-79.8 50.9-89.9 55.8c-91.1 44.7-164.9 16.8-164.9 16.8s119.9 3 158.4-27.3l-22.6-34s-82.8-2.3-112.3-6.2c-15.4-2-48.7-18.8-73.1-18.8z"}></path>
                            </svg>
                            <div className={"vertical-center text-center register-content"}>
                                <h5>{t('register_seller_title')}</h5>
                                <p>{t('register_seller_text')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className={"bg-main"}>
                <Container className={"content"}>
                    <Row>
                        <Col lg={7} className="indexText">
                            <div className={"our-mission"}>
                                <img src="/images/mission.png" className={"mission-logo"} alt="onze missie" />
                                <h5>{t('mission_title')}</h5>
                                <p>{t('mission_text')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="section-divider"></div>
                <Container className={"content"}>
                    <Row className={"mt-4"}>
                        <Col lg={5}></Col>
                        <Col lg={7}>
                            <div className={"our-mission text-end content-padding-bottom"}>
                                <img src="/images/vision.png" className={"mission-logo"} alt="onze visie" />
                                <h5>{t('vision_title')}</h5>
                                <p>{t('vision_text')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className={"content content-padding-bottom"}>
                <h3 className={"text-center"}>{t('sold_auctions_title')}</h3>
                {loading ? (
                    <p>{t('loading_auctions')}</p>
                ) : paidAuctions.length > 0 ? (
                    <Row>
                        {paidAuctions.map((auction) => (
                            <Col lg={3} md={6} key={auction.id}>
                                <CarCard
                                    title={`${auction.car.brand_name} ${auction.car.model}`}
                                    buildyear={auction.car.build_year}
                                    mileage={auction.car.kilometers}
                                    fuelType={auction.car.fuel_type}
                                    image={auction.images.length > 0 ? auction.images[0].url : "/images/default_car.jpg"}
                                    auctionId={auction.id}
                                />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <p className="index-paid-text">{t('no_paid_auctions')}</p>
                )}
            </Container>

            <div className={"bg-main"}>
                <Container className={"content"}>
                    <Row>
                        <Col lg={6}>
                            <Lottie animationData={registerAnimation} loop={true} />
                        </Col>
                        <Col lg={6} className={"vertical-center text-center"}>
                            <h3 className={"after-icon"}>{t('create_account_title')}</h3>
                            <p>{t('create_account_text1')}<br />
                                {t('create_account_text2')}<br />
                                <strong>{t('create_account_text3')}</strong></p>
                        </Col>
                    </Row>
                </Container>
                <div className="section-divider"></div>
                <Container className={"content-padding-bottom"}>
                    <Row>
                        <Col lg={6} className={"vertical-center text-center"}>
                            <h3>{t('auctions_title')}</h3>
                            <p>{t('auctions_text1')}<br /><br />{t('auctions_text2')}<br /><br />
                                <strong>{t('auctions_text3')}</strong></p>
                        </Col>
                        <Col lg={6} className={"mx-auto"}>
                            <Lottie animationData={carAnimation} loop={true} style={{ transform: "scale(1.3)" }} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}