import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Button, Carousel, Col, Form, Modal, Row, Card, Collapse } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faCrown, faCar, faTachometerAlt, faDoorOpen, faCog, faGasPump, faTools, faChevronDown, faChevronUp, faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../contexts/AuthContext";
import { UserNavigation } from "../components/UserNavigation";
import { FaInfoCircle, FaClock } from "react-icons/fa";
import moment from 'moment-timezone';

export function AuctionDetailsPage(props) {
    const { id } = useParams();
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const user = userMetadata;
    const [auction, setAuction] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [show, setShow] = useState(false);
    const [formAmount, setFormAmount] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showBids, setShowBids] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [openDamages, setOpenDamages] = useState(false);
    const [btwCarPercentage, setBtwCarPercentage] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [applicationFee, setApplicationFee] = useState(0);
    const [btwUsell, setBtwUsell] = useState(0);
    const [btwCar, setBtwCar] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const handleClose = () => {
        setShow(false);
        setErrorMessage("");
    };
    const handleShow = () => setShow(true);
    const handleShowBids = () => setShowBids(true);
    const handleCloseBids = () => setShowBids(false);

    const handleModalOpen = (content) => {
        setModalContent(content);
        setShowModal(true);
    };

    const handleModalClose = () => setShowModal(false);

    const addBid = async (e) => {
        e.preventDefault();
        const URL = `https://usell.be/api/add-bid/${id}/`;
        const token = apiAccessToken;
        const options = {
            method: "PATCH",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                bids: [
                    {
                        user_id: user.id,
                        amount: formAmount,
                        date: new Date().toISOString(),
                    },
                ],
            }),
        };

        try {
            const response = await fetch(URL, options);
            const data = await response.json();

            if (!response.ok) {
                if (data.error && data.min_increment) {
                    setErrorMessage(`Uw bod moet minimaal €${data.min_increment} hoger zijn dan het huidige hoogste bod.`);
                } else {
                    setErrorMessage(data.error || "Er is een fout opgetreden.");
                }
                return;
            }

            setAuction(data.auction_data);
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    const getBtwStelselText = () => {
        if (!auction || !auction.car) return "Onbekend";
        const carBtw = parseFloat(auction.car.car_btw);
        if (carBtw === 0) return "0%";
        if (carBtw === 10.5) return "50%";
        if (carBtw === 21) return "100%";
        return "Onbekend";
    };

    useEffect(() => {
        const URL = `https://usell.be/api/auction/${id}/`;
        const token = apiAccessToken;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        const fetchData = async () => {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
                const data = await response.json();
                setAuction(data);

                const endTime = moment(data.end_date);
                const now = moment();
                const remainingTime = endTime.diff(now, 'seconds');
                setTimeRemaining(remainingTime > 0 ? remainingTime : 0);

                if (data.car && data.car.car_btw) {
                    setBtwCarPercentage(data.car.car_btw);
                }

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [id, apiAccessToken]);

    useEffect(() => {
        const calculateCosts = (bidAmount) => {
            if (bidAmount > 0) {
                const applicationFeePercentage = bidAmount > 90000 ? 9.5 : 6.5;
                const appFee = (bidAmount * applicationFeePercentage) / 100;
                const btwU = appFee * 0.21;
                const appFeeExclBtw = appFee + btwU;
                const btwC = (bidAmount * btwCarPercentage) / 100;
                const total = bidAmount + appFeeExclBtw + btwC;

                setApplicationFee(appFee);
                setBtwUsell(btwU);
                setBtwCar(btwC);
                setTotalAmount(total);
            } else {
                setApplicationFee(0);
                setBtwUsell(0);
                setBtwCar(0);
                setTotalAmount(0);
            }
        };

        calculateCosts(formAmount);
    }, [formAmount, btwCarPercentage, auction]);

    
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    
        return () => clearInterval(interval);
    }, [timeRemaining]);
    
    const formatTimeRemaining = (timeRemaining) => {
        if (timeRemaining === 0) return "Auction Ended";
        const duration = moment.duration(timeRemaining, 'seconds');
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    if (!auction) return <h1>LOADING</h1>;

    const highestBid = auction.bids.length > 0
        ? Math.max(...auction.bids.map(bid => bid.amount))
        : null;
    const winningBid = auction.bids.length > 0 ? auction.bids[auction.bids.length - 1] : null;

    const DocumentStatus = ({ label, url }) => (
        <div className="d-flex align-items-center me-4">
            <span className="me-2">{label}</span>
            <FontAwesomeIcon
                icon={url ? faCheckCircle : faTimesCircle}
                style={{ color: url ? 'green' : 'red' }}
            />
        </div>
    );

    return (
        <>
            <UserNavigation />
            <div className={"auctions-hero"}>
                <div className={"hero-background detail-auctions-background"} style={{
                    backgroundImage: "url('/images/cars/audi.jpeg')",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg mobiel-auction"}>
                    <h2 className="shadow-text auction-detail-page-p">{auction.name || "OPEL CORSA TUNING"}</h2>
                    <h4 className={"fw-bold shadow-text auction-detail-page-p"}>Huidig Hoogste Bod:
                        {highestBid !== null ? (
                            <><FontAwesomeIcon icon={faEuroSign} />{highestBid}</>
                        ) : (
                            "Nog geen biedingen beschikbaar"
                        )}</h4>
                    <h5 className="shadow-text auction-detail-page-p">Vanaf <FontAwesomeIcon icon={faEuroSign} />{auction.bottom_price}</h5>
                </Container>
            </div>
            <Container className={"content detail"}>
                <Row>
                    <Col lg={5}>
                        <Carousel className={"car-carousel"}>
                            {auction.images.map((image, index) => (
                                <Carousel.Item interval={1000} key={index}>
                                    <img src={image.url} alt="car" className="rounded-carousel-img" />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                    <Col lg={7}>
                        <Col className="text-end" style={{ fontSize: '2rem', color: '#2196F3' }}>
                            <div className="d-flex align-items-center">
                                <FaClock className="me-2" />
                                <span className="shadow-text">
                                    {formatTimeRemaining(timeRemaining)}
                                </span>
                            </div>
                        </Col>
                        <Row>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <h4 className="shadow-text" style={{ color: '#2196F3' }}>ALGEMEEN</h4>
                                        <ul className="car-specs-list">
                                            <li><FontAwesomeIcon icon={faCar} /> {auction.car.brand_name} {auction.car.model} {auction.car.build_year}</li>
                                            <li><FontAwesomeIcon icon={faCar} /> {auction.car.generation} {auction.car.series}</li>
                                            <li><FontAwesomeIcon icon={faTachometerAlt} /> {auction.car.kilometers} KM</li>
                                            <li><FontAwesomeIcon icon={faDoorOpen} /> {auction.car.number_of_doors} deur(en)</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <h4 className="shadow-text" style={{ color: '#2196F3' }}>AANDRIJVING</h4>
                                        <ul className="car-specs-list">
                                            <li><FontAwesomeIcon icon={faCog} /> {auction.car.engine}</li>
                                            <li><FontAwesomeIcon icon={faCog} /> {auction.car.drive}</li>
                                            <li><FontAwesomeIcon icon={faTools} /> {auction.car.transmission}</li>
                                            <li><FontAwesomeIcon icon={faGasPump} /> {auction.car.fuel_type}</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="shadow-text" style={{ color: '#2196F3' }}>FINANCIERING</h4>
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon
                                                    icon={auction.financing ? faCheckCircle : faTimesCircle}
                                                    style={{ color: auction.financing ? "green" : "red", marginRight: "8px" }}
                                                />
                                                <FaInfoCircle
                                                    className="text-primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleModalOpen("Financiering informatie: beschikbaar afhankelijk van financieringsstatus van de auto. Als deze een groene check toont is er financiering lopend. Als het een rood kruis toont dan valt deze wagen niet onder financiering en is er geen lopende betaling.")}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="shadow-text" style={{ color: '#2196F3' }}>BTW STELSEL</h4>
                                            <div className="d-flex align-items-center">
                                                {getBtwStelselText(auction.car.car_btw)}
                                                <FaInfoCircle
                                                    className="text-primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleModalOpen("tekst btw stelsel")}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="shadow-text" style={{ color: '#2196F3' }}>OPTIES</h4>
                                            <FontAwesomeIcon
                                                icon={openOptions ? faChevronUp : faChevronDown}
                                                onClick={() => setOpenOptions(!openOptions)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <Collapse in={openOptions}>
                                            <ul className="car-specs-list">
                                                {auction.car.options.length > 0 ? (
                                                    auction.car.options.map(option => (
                                                        <li key={option.id}>{option.description}</li>
                                                    ))
                                                ) : (
                                                    <li>Geen opties beschikbaar</li>
                                                )}
                                            </ul>
                                        </Collapse>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="shadow-text" style={{ color: '#2196F3' }}>SCHADEPUNTEN</h4>
                                            <FontAwesomeIcon
                                                icon={openDamages ? faChevronUp : faChevronDown}
                                                onClick={() => setOpenDamages(!openDamages)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <Collapse in={openDamages}>
                                            <ul className="car-specs-list">
                                                {auction.car.damages.length > 0 ? (
                                                    auction.car.damages.map(damage => (
                                                        <li key={damage.id}>{damage.description}</li>
                                                    ))
                                                ) : (
                                                    <li>Geen schadepunten beschikbaar</li>
                                                )}
                                            </ul>
                                        </Collapse>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="shadow-text" style={{ color: '#2196F3' }}>DOCUMENTEN</h4>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <DocumentStatus
                                                label="Gelijkvormigheidsattest"
                                                url={auction.car.boorddocument_url}
                                            />
                                            <DocumentStatus
                                                label="Inschrijvingsbewijs"
                                                url={auction.car.kenteken_url}
                                            />
                                            <DocumentStatus
                                                label="Keuringsattest"
                                                url={auction.car.kenteken2_url}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col sm={9}>
                        <h4 className="shadow-text auction-detail-page" style={{ color: '#2196F3' }}>BIEDINGEN</h4>
                        {winningBid && (
                            <div className="winning-bid auction-detail-page-p2">
                                <FontAwesomeIcon icon={faCrown} className="text-warning me-2" />
                                Winnende Bod: <FontAwesomeIcon icon={faEuroSign} />{winningBid.amount}
                            </div>
                        )}
                    </Col>
                    <Col sm={3} className="d-flex justify-content-end">
                        {user.type !== "seller" && (
                            <Button
                                variant="primary"
                                onClick={handleShow}
                                className="me-2"
                                disabled={auction.status !== "ONGOING"}
                            >
                                Maak bod
                            </Button>
                        )}
                        <Button variant="secondary" onClick={handleShowBids} className="ms-2">
                            Bekijk alle biedingen
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={addBid}>
                    <Modal.Header closeButton>
                        <Modal.Title>Plaats uw bod</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBidAmount">
                            <Form.Label>Bedrag</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={parseFloat(highestBid).toFixed(2)}
                                value={formAmount}
                                onChange={e => setFormAmount(parseFloat(e.target.value))}
                                autoFocus
                            />
                        </Form.Group>
                        <div className="mt-3">
                            <p>Application fee: <FontAwesomeIcon icon={faEuroSign} />{applicationFee.toFixed(2)} ( .excl BTW)</p>
                            <p>BTW Usell: <FontAwesomeIcon icon={faEuroSign} />{btwUsell.toFixed(2)}</p>
                            <p>BTW Car: <FontAwesomeIcon icon={faEuroSign} />{btwCar.toFixed(2)}</p>
                            <p><strong>Totaal bedrag: <FontAwesomeIcon icon={faEuroSign} />{totalAmount.toFixed(2)}</strong></p>
                        </div>
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuleer
                        </Button>
                        <Button type="submit" variant="primary">
                            Plaats bod
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showBids} onHide={handleCloseBids}>
                <Modal.Header closeButton>
                    <Modal.Title>Alle biedingen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {auction.bids.map((bid, index) => (
                            <li key={index}>
                                <FontAwesomeIcon icon={faEuroSign} />{bid.amount} (Geboden op: {new Date(bid.date).toLocaleString()})
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseBids}>
                        Sluiten
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Extra Informatie</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Sluiten
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}