import { Col } from "react-bootstrap";
import { CarCard } from "./CarCard";
import { Link } from "react-router-dom";

export function CarList({ auctions }) {
    if (!auctions) return "Loading";

    return (
        <>
            {auctions.map(auction => (
                <Col lg={4} md={6} className="mb-4" key={auction.id}>
                    <Link to={`/veiling/${auction.id}`}>
                        <CarCard
                            title={auction.name}
                            buildyear={auction.car.build_year}
                            mileage={auction.car.kilometers}
                            fuelType={auction.car.fuel_type}
                            image={auction.image_url || "/images/cars/opel.webp"}
                            endDate={auction.end_date}
                            highestBid={auction.highest_bid}
                            auctionId={auction.id} 
                        />
                    </Link>
                </Col>
            ))}
        </>
    );
}