import React from 'react';
import { Container } from 'react-bootstrap';
import { UserNavigation } from '../../../components/UserNavigation';

export function SuccessOnboardingPage() {
    return (
        <>
            <UserNavigation />
            <div className="content-padding-top-lg content-padding-bottom-lg">
                <Container>
                    <h1>Registratie bij Stripe is gelukt!</h1>
                    <p>Dank u om uw registratie te vervolledigen, U kan nu zonder problemen betalingen ontvangen.</p>

                </Container>
            </div>
        </>
    );
}