import Container from "react-bootstrap/Container";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export function ContactPage(props) {

    const { t } = useTranslation();

    return (
        <>
            <section className="contact-section">
                <div className={"contact-hero"}>
                    <div className={"hero-background"}></div>
                    <Container className={"content content-padding-bottom-lg"}>
                        <h1>{t('contact_title')}</h1>
                        <h4>{t('contact_text1')}<br /> {t('contact_text2')}</h4>
                    </Container>
                </div>
                <Container className={"contact-container content"}>
                    <Row>
                        <Col className={"col"} lg={9}>
                            <div className={"form-wrapper"}>
                                <h3>{t('contact_title2')}</h3>
                                <p>{t('contact_text3')} <a href="/faq">{t('contact_text4')}</a> {t('contact_text5')}</p>
                                <hr />
                                <Form>
                                    <Row className={"mb-0"}>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3 fw-bold" controlId="cf.name">
                                                <Form.Label>{t('naam')}</Form.Label>
                                                <Form.Control type="text" placeholder={t('naam_placeholder')} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3 fw-bold" controlId="cf.email">
                                                <Form.Label>{t('email')}</Form.Label>
                                                <Form.Control type="email" placeholder={t('email_placeholder')} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group className="mb-3 fw-bold" controlId="cf.subject">
                                        <Form.Label>{t('onderwerp')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('onderwerp_placeholder')} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 fw-bold" controlId="cf.message">
                                        <Form.Label>{t('bericht')}</Form.Label>
                                        <Form.Control as="textarea" className={"cf-textarea"} rows={3} />
                                    </Form.Group>
                                    <Form.Check
                                        inline
                                        label={t('akkoord')}
                                        name="gdpr"
                                        type={"checkbox"}
                                        id={"gdpr"}
                                    />
                                    <button className={"join-button submit-form mt-2"}>{t('contact_button')}</button>
                                </Form>
                            </div>

                        </Col>
                        <Col lg={3}>
                            <div className={"form-wrapper"}>
                                <p className={"fw-bold"}>{t('socials')}</p>
                                <a href={"mailto:info@usell.be"}><i
                                    className={"bi bi-envelope-at-fill text-lg-start"}></i> info@usell.be</a><br />
                                <a href={"https://x.com"}><i className={"bi bi-twitter-x"}></i> Twitter/X</a><br />
                                <a href={"https://facebook.com"}><i className={"bi bi-facebook"}></i> Facebook/META</a><br />
                                <a href={"https://instagram.com"}><i className={"bi bi-instagram"}></i> Instagram/META</a><br />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}