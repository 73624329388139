import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { UserNavigation } from "../../components/UserNavigation";
import { FaCalendarAlt, FaTimes, FaClock, FaCheckCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

export function AppointmentsPage() {
  const [appointments, setAppointments] = useState([]);
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const user = userMetadata;
  const token = apiAccessToken;
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/appointments/user/${user.id}/all/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        console.log("Appointments:", data);
        setAppointments(data);
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      }
    };

    if (user) {
      fetchAppointments();
    }
  }, [user, token]);

  const handleAppointmentClick = (appointmentId) => {
    navigate(`/dashboard/agenda/${appointmentId}`);
  };

  const handleProceedToSale = async (appointmentId, userType) => {
    try {
      const response = await fetch(`https://usell.be/api/appointments/${appointmentId}/proceed-to-sale/`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_type: userType }),
      });

      if (!response.ok) throw new Error("Failed to proceed to sale");

      window.location.reload();

    } catch (error) {
      console.error("Error proceeding to sale:", error);
    }
  };

  const handleDeclineAppointment = async (appointmentId) => {
    try {
      const response = await fetch(`https://usell.be/api/appointments/${appointmentId}/decline-appointment/`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${apiAccessToken}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to decline appointment");

      window.location.reload();

    } catch (error) {
      console.error("Error declining appointment:", error);
    }
  };

  const handleMarkCompleted = async (appointmentId) => {
    try {
      const response = await fetch(`https://usell.be/api/appointments/${appointmentId}/mark-completed/`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to mark appointment as completed.");

      window.location.reload();

    } catch (error) {
      console.error("Error marking appointment as completed:", error);
    }
  };

  const shouldDisableButton = (app) => {
    if (userMetadata.type === 'seller') {
      return app.seller_accepted === true;
    } else if (userMetadata.type === 'buyer') {
      return app.buyer_accepted === true;
    }
    return false;
  };

  const renderButtons = (app) => {
    const sellerHasSelectedSlots = app.slots && app.slots.filter(slot => slot.selected_slot_seller).length >= 5;
    const isAppointmentDatePassed = new Date(app.appointment_date) < new Date();
    const isOwner = app.seller === user.id;
    const userType = isOwner && userMetadata.type === "buyer" ? "seller" : userMetadata.type;

    if (app.status === "ACCEPTED") {
      if (userType === "seller" || userType === "company") {
        console.log("Rendering ACCEPTED view for seller/company");
        return <p>{t('appointment1')}</p>;
      } else if (userType === "buyer") {
        console.log("Rendering ACCEPTED view for buyer");
        return (
          <>
            <Button
              variant="danger"
              className="ms-2"
              disabled={!isAppointmentDatePassed}
              onClick={() => handleDeclineAppointment(app.id)}
            >
              <FaTimes className="me-1" /> {t('appointment2')}
            </Button>
            <Button
              variant="success"
              className="ms-2"
              disabled={!isAppointmentDatePassed}
              onClick={() => isAppointmentDatePassed && handleMarkCompleted(app.id)}
            >
              <FaCheckCircle className="me-1" /> {t('appointment3')}
            </Button>
          </>
        );
      }
    }

    if (app.status === "Pending") {
      if (userType === "seller" || userType === "company") {
        console.log("Rendering PENDING view for seller/company");
        if (sellerHasSelectedSlots) {
          return <p>{t('appointment5')}</p>;
        } else {
          return (
            <Button variant="primary" onClick={() => handleAppointmentClick(app.id)}>
              {t('appointment4')}
            </Button>
          );
        }
      } else if (userType === "buyer") {
        console.log("Rendering PENDING view for buyer");
        if (sellerHasSelectedSlots) {
          return (
            <Button variant="primary" onClick={() => handleAppointmentClick(app.id)}>
              {t('appointment6')}
            </Button>
          );
        } else {
          return <p>{t('appointment7')}</p>;
        }
      }
    }

    if (app.status === "COMPLETED") {
      if (userType === "seller" || userType === "company") {
        console.log("Rendering COMPLETED view for seller/company");
        return (
          <Button
            variant="success"
            disabled={shouldDisableButton(app)}
            onClick={() => handleProceedToSale(app.id, 'seller')}
          >
            {t('appointment8')}
          </Button>
        );
      }

      if (userType === "buyer") {
        console.log("Rendering COMPLETED view for buyer");
        return (
          <Button
            variant="success"
            disabled={shouldDisableButton(app)}
            onClick={() => handleProceedToSale(app.id, 'buyer')}
          >
            {t('appointment10')}
          </Button>
        );
      }
    }

    return null;
  };

  const renderAppointments = (status) => {
    return appointments
      .filter(app =>
        app.status.toLowerCase() === status.toLowerCase() &&
        ((!app.auction || app.auction.status !== 'FINISHED') ||
          (!app.stockItem || app.stockItem.status !== 'FINISHED'))
      )
      .map(app => (
        <Card key={app.id} className="mb-3 appointment-card">
          <Card.Body>
            <Card.Title>
              <FaCalendarAlt className="me-2" />
              {app.appointment_date ? new Date(app.appointment_date).toLocaleString() : 'Nog geen datum'}
            </Card.Title>
            <Card.Text>Status: {app.status}</Card.Text>
            {renderButtons(app)}
          </Card.Body>
        </Card>
      ));
  };
  if (!userMetadata) return "Loading...";

  return (
    <>
      <UserNavigation />
      <div className={"appointments-hero"}>
        <div className={"hero-background appointments-background"}></div>
        <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
          <h1 className={"appointments-title"}>{t('appointment13')}</h1>
        </Container>
      </div>
      <Container className={"afspraken"}>
        <Row>
          <Col md={4}>
            <div className="title-box pending-box">
              <FaClock className="me-2" />
              <h3 className="titel-afspraken">{t('appointment14')}</h3>
            </div>
            {renderAppointments("Pending")}
          </Col>
          <Col md={4}>
            <div className="title-box accepted-box">
              <FaCheckCircle className="me-2" />
              <h3 className="titel-afspraken">{t('appointment15')}</h3>
            </div>
            {renderAppointments("Accepted")}
          </Col>
          <Col md={4}>
            <div className="title-box completed-box">
              <FaCheckCircle className="me-2" />
              <h3 className="titel-afspraken">{t('appointment16')}</h3>
            </div>
            {renderAppointments("Completed")}
          </Col>
        </Row>
      </Container>
    </>
  );
}